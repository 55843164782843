import React from 'react';

import LocationPageTemplate from '../../components/Location/LocationPageTemplate';

function CanadaOffice() {
  return (
    <LocationPageTemplate
      mapSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.2519403436836!2d-79.64827638432072!3d43.64292636111272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b38b5b5eaaaa9%3A0xc80298d68860dc3!2s5800%20Ambler%20Dr%20Suite%20%23210%2C%20Mississauga%2C%20ON%20L4W%204J4%2C%20Canada!5e0!3m2!1sen!2s!4v1620316749062!5m2!1sen!2s"
      analyticsPageviewTitle="Location/Canada"
      city="Canada"
      addresses={[
        {
          title: 'Tintash Canada Inc',
          description: (
            <>
              5800 Ambler Drive, Unit 210, Mississauga, ON L4W4J4
              <br />
              Phone: +1 (647) 987-7933
            </>
          ),
        },
      ]}
      locationImgSrc={require('../../assets/images/location/canada.png').default}
    />
  );
}

export default CanadaOffice;
